<template>
  <div class="md-shared">
    <md-shared-header />
    <main class="md-shared-main">
      <router-view />
    </main>
    <md-shared-footer />
  </div>
</template>

<script>
import MdSharedHeaderComponent from "./header";
import MdSharedFooterComponent from "./footer";
export default {
  name: "MdSharedComponent",
  components: {
    "md-shared-header": MdSharedHeaderComponent,
    "md-shared-footer": MdSharedFooterComponent,
  },
};
</script>

<style lang="less" scoped>
.md-shared {
  width: 100%;
  box-sizing: border-box;
  // padding-top: @md-header-height;
  .md-shared-main {
    width: 100%;
    min-width: 1200px;
    min-height: calc(100vh - 262px);
    background: @md-bgc-primary-color;
    box-sizing: border-box;
    margin: 0 auto;
  }
}
</style>
